import { http } from '@/plugins/http';

import { CARD_PAYMENT_TOKEN_URL, ORDER_RETURN_REQUEST, PLACE_ORDER_URL, SAVE_ORDER_URL } from '@/utils/api';

import { getApiResponse } from '@/utils/helper';

export const saveOrder = (data) => getApiResponse(() => http.post(SAVE_ORDER_URL, data));

export const placeOrder = (data) => getApiResponse(() => http.post(PLACE_ORDER_URL, data));

export const getCardPaymentToken = () => getApiResponse(() => http.post(CARD_PAYMENT_TOKEN_URL));

export const returnOrderedProduct = (data) => getApiResponse(() => http.post(ORDER_RETURN_REQUEST, data));
